import React, { useContext } from "react"
import { GeneralContext } from "../../contexts/generalContext"
import tw, { css } from "twin.macro"

const CTAButtonsInHero = () => {
  const generalContext = useContext(GeneralContext)
  const { ctaModalShow } = generalContext
  return (
    <div
    css={tw`flex md:flex-row flex-col gap-4`}
    >
      <div
        onClick={() => ctaModalShow('contacto')}
        css={tw`inline-block py-2 px-4 text-center text-marron1 hover:text-white text-obmed md:text-obmed3 font-bold uppercase text-marron1 bg-white hover:bg-marron1 cursor-pointer`}
      >
        <span>Infórmate ahora</span>
      </div>
      <div
        onClick={() => ctaModalShow('cita')}
        css={tw`inline-block py-2 px-4 text-center text-marron1 hover:text-white text-obmed md:text-obmed3 font-bold uppercase text-marron1 bg-white hover:bg-marron1 cursor-pointer`}
      >
        <span>Pide una cita</span>
      </div>
    </div>
  )
}

export default CTAButtonsInHero